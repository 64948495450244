import React from "react";
import { Link } from "react-router-dom";
import isoLogo from "../images/isoaccredication.png";
let locations = [
  "INDIA",
  "OMAN",
  "QATAR",
  "SAUDI ARABIA",
  "KUWAIT",
  "EGYPT",
  "KENYA",
  "UGANDA",
  "ETHIOPIA",
  "NIGERIA",
  "GHANA",
  "SOUTH AFRICA",
  "CANADA",
  "SINGAPORE",
  "MALAYSIA",
  "JORDAN",
];
function Footer() {
  const mapLocations = () => {
    return locations.map((element, i) => {
      return (
        <div key={i} className="padding-right text-white col-md-2">
          {element}
        </div>
      );
    });
  };
  return (
    <div>
      <section className="section footer bg-dark text-white">
        <div className="container">
          <div className="row">
            <div className="col-md-3 mt-3">
              <h6 className="text-orange">Information</h6>
              <hr />
              <p className="text-white">
                TopMetric is one of the few consulting organizations in the
                world that has domain expertise in the majority of all industry
                sectors. Our basket is full of various ISO Certifications as
                well as Compliances.
              </p>
            </div>
            <div className="col-md-3 mt-3">
              <h6 className="text-orange">Quick links</h6>
              <hr />
              <div>
                <Link to="/" className="info">
                  Home
                </Link>
              </div>
              <div>
                <Link to="/about" className="info">
                  About
                </Link>
              </div>
              <div>
                <Link to="/consulting" className="info">
                  Consulting
                </Link>
              </div>
              <div>
                <Link to="/standards" className="info">
                  Standards
                </Link>
              </div>
              <div>
                <Link to="/contact" className="info">
                  Contact
                </Link>
              </div>
            </div>
            <div className="col-md-3 mt-3">
              <h6 className="text-orange">Accreditation</h6>
              <hr />
              <img src={isoLogo} alt="ISO" className="iso-logo" />
            </div>

            <div className="col-md-3 mt-3">
              <h6 className="text-orange">Get In Touch</h6>
              <hr />
              <div>
                <p className="text-white mb-1">
                  Sree Adithya Lotus Towers, 309,
                  <br /> Sonnenahalli,KR Puram,
                  <br />
                  Banglore, Karnataka 560049.
                </p>
                <br />
                <p className="text-orange mb-1">
                  <a
                    className="link text-orange"
                    href="tel:+919987998752"
                    target="_blank"
                    rel="noreferrer"
                  >
                    +91 9987 9987 52
                  </a>
                </p>
              </div>
              <div>
                <p className="text-orange mb-1">
                  <a
                    className="link text-orange"
                    href="mailto: Info@topmetricgroup.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Info@topmetricgroup.com
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section footer bg-dark text-white">
        <div className="container">
          <div className="row">
            <h6 className="text-orange">Global Locations</h6>
            <hr />
            {mapLocations()}
          </div>
        </div>
      </section>
    </div>
  );
}
export default Footer;
