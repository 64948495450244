import React from "react";
import { Link } from "react-router-dom";
import Standards from "../../standards.json";
import home from '../images/home.png';
function Services() {
  const ServicesStandards = () => {
    window.scrollTo(0, 0);

    return Standards.standards.map((element, i) => {
      return (
        <div className="col-lg-4 d-flex mb-3 align-items-stretch" key={i}>
          <div className="card shadow">
            <div className="py-2 text-center">
              <h2>{element.header}</h2>
            </div>
            <div className="card-body">
              <p className="text">{element.content}</p>
              <Link to={element.Route} className="btn btn-link text-orange ">
                read more
              </Link>
            </div>
          </div>
        </div>
      );
    });
  };
  return (
    <div className="padding">
    <section className="section  border-top padding">
      <div className="container">
        <div className="row">
          <div className="col-md-12 mb-5 text-center">
            <h3 className="main-heading">Our Services</h3>
            <div className="underline mx-auto"></div>
          </div>
          {ServicesStandards()}
        </div>
          <Link to="/">
            <img src={home} alt="home" className="home" />
            <p className="home-page">Home</p>
          </Link>
      </div>
    </section>
    </div>
  );
}
export default Services;
