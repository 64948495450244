import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import logo from "../images/topmetriclogo.png";
function Navbar() {
  const toggle = () => {
    const menuToggle = document.getElementById("navbarSupportedContent");
    const bsCollapse = new bootstrap.Collapse(menuToggle);
    bsCollapse.toggle();
  };
  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark shadow">
      <div className="container-fluid">
        <Link to="/" className="navbar-brand">
          <img src={logo} alt="logo" className="logo-image" />
          <div className="header">TopMetric</div>
        </Link>
        <Link to="/" className="nav-link active status">
          Call us @&nbsp;&nbsp;
          <a
            className="link text-orange"
            href="tel:+919987998752"
            target="_blank"
            rel="noreferrer"
          >
            +91 9987-9987-52&nbsp;&nbsp;
          </a>
          OR Email :&nbsp;&nbsp;
          <a
            className="link text-orange"
            href="mailto: Info@topmetricgroup.com"
            target="_blank"
            rel="noreferrer"
          >
            Info@topmetricgroup.com
          </a>
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            <li
              className="nav-item zindex"
              onClick={() => {
                toggle();
              }}
            >
              <Link to="/" className="nav-link active ">
                Home
              </Link>
            </li>
            <li
              className="nav-item"
              onClick={() => {
                toggle();
              }}
            >
              <Link to="/about" className="nav-link active">
                About Us
              </Link>
            </li>
            <li
              className="nav-item"
              onClick={() => {
                toggle();
              }}
            >
              <Link to="/standards" className="nav-link active">
                Our Services
              </Link>
            </li>
            <li
              className="nav-item"
              onClick={() => {
                toggle();
              }}
            >
              <Link to="/consulting" className="nav-link active">
                Consulting
              </Link>
            </li>
            <li
              className="nav-item"
              onClick={() => {
                toggle();
              }}
            >
              <Link to="/contact" className="nav-link active">
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
export default Navbar;
