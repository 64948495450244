import React from "react";
import Slider from "../inc/slider";
import VMC from "../inc/vmc";
import Services from "../inc/services";
import Contact from "./contact";
import Consulting from "../inc/consulting";
import Feedback from "../inc/testimonials";
function Home() {
  window.scrollTo(0, 0);
  return (
    <div className="col-md-12 padding">
   
      <Slider />
      <section className="section  border-bottom">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center landing-page">
              <h2 className="main-heading" >At TopMetric</h2>
              <div className="underline mx-auto"></div>
              <h1 className="font-head">
              You Build your Business We take care of your Consulting Process
              </h1>
            </div>
          </div>
        </div>
      </section>
      <section className="section bg-c-light">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h3 className="main-heading">TopMetric</h3>
              <div className="underline mx-auto"></div>
              <p className="p-tag-home">
                We here at TopMetric believe in delivering quality. Perfection
                is guaranteed. With the help of our highly expert and qualified
                professionals, it is our duty to guide you to choose the right
                ISO certificate/compliance for your beloved business. We take
                care of your requirement with utmost professionalism.<br/> We eye on
                delivering true business benefits with our consultation process.
                Our approach is straight forward and easy to understand.<br/>
                Together, we are a handful professionals, taking care of our
                global customer base and an illustrious service portfolio that
                covers all the International Quality Certifications.
              </p>
              {/* <Link to="/about" className="btn btn-warning shadow">
                Read More
              </Link> */}
            </div>
          </div>
        </div>
      </section>
      <VMC />
      <Consulting />
      <Services />
      <Feedback  />
      <Contact home={true}/>
    </div>
  );
}
export default Home;
