import React from "react";
import home from "../images/home.png";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
function Contact(props) {
  const location = window.location.pathname;
  window.scrollTo(0, 0);
  const sendEmail = (e) => {
    e && e.preventDefault && e.preventDefault();
    console.log(e.target[0].value);
    let formData = {};
    formData.name = e.target[0].value;
    formData.phone = e.target[1].value;
    formData.to_email = e.target[2].value;
    formData.subject = "Query From " + e.target[0].value;
    formData.message = e.target[3].value;

    document.getElementById("myform").reset();
    window.emailjs.send("service_huysw98", "template_9vohlof", formData).then(
      function (response) {
        toast("Mail Sent Sucessfully...!");
        // window.location = "https://topmetricthankyoupage-1362c.web.app/";
      },
      function (error) {
        console.log("FAILED...", error);
      }
    );
  };
  return (
    <div className={props.home ? "" : "padding"}>
      <section className="py-4 bg-info">
        <div className="container">
          <div className="row">
            <div className="col-md-4 my-auto">
              <h4>Contact Us</h4>
            </div>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <div className="card shadow">
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  <h4>Contact Us For Free Quotation</h4>
                  <hr />
                  <form id="myform" onSubmit={sendEmail}>
                    <div className="form-group">
                      <label className="mb-1">Full Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Full Name"
                        required={true}
                      />
                    </div>
                    <div className="form-group">
                      <label className="mb-1">Phone</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Phone"
                        required={true}
                      />
                    </div>
                    <div className="form-group">
                      <label className="mb-1">Email Address</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Email Address"
                        required={true}
                      />
                    </div>
                    <div className="form-group">
                      <label className="mb-1">Message</label>
                      <textarea
                        rows="3"
                        className="form-control"
                        placeholder="Type Your Message..."
                        required={true}
                      />
                    </div>
                    <div className="form-group py-3">
                      <button
                        type="submit"
                        className="btn btn-primary shadow w-100 bg-orange btn-clr"
                      >
                        Send Email
                      </button>
                    </div>
                  </form>
                </div>

                <div className="col-md-6 border-start">
                  <h5 className="main-heading">Address Information</h5>
                  <div className="underline"></div>
                  <h5>TopMetric</h5>
                  <p>
                    Sree Adithya Lotus Towers, 309,
                    <br /> Sonnenahalli,KR Puram,
                    <br />
                    Banglore, Karnataka 560049.
                  </p>
                  <p>
                    Phone :
                    <a
                      className="link text-orange"
                      href="tel:+919987998752"
                      target="_blank"
                      rel="noreferrer"
                    >
                      +91 9987 9987 52
                    </a>
                  </p>
                  <p>
                    Email :&nbsp;
                    <a
                      className="link text-orange"
                      href="mailto: Info@topmetricgroup.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Info@topmetricgroup.com
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {location === "/contact" ? (
          <Link to="/">
            <img src={home} alt="home" className="home" />
            <p className="home-page">Home</p>
          </Link>
        ) : (
          ""
        )}
      </section>
    </div>
  );
}
export default Contact;
