import React from "react";
import { Link } from "react-router-dom";
import home from "../images/home.png";
const excludeList = [
  "VAPT",
  "PCI DSS",
  "CE MARKING",
  "CMMI",
  "HALAL",
  "HIPAA",
  "EU GDPR",
  "HACCP",
  "SOC",
];
function BASE(props) {
  window.scrollTo(0, 0);
  const content = props.Data;
  const step = () => {
    return content?.steps?.map((element, i) => {
      return (
        <div className="col-md-4 mb-2 align-items-stretch" key={i}>
          <div className="card" style={{ width: "18rem" }}>
            <div className="card-body">
              <h5 className="card-title text-orange">{element}</h5>
              <h6 className="card-text">{content.Answers[i]}</h6>
            </div>
          </div>
        </div>
      );
    });
  };
  const questions = () => {
    return content?.questions?.map((element, i) => {
      if (i % 2 == 0) {
        return (
          <section className="section" key={i}>
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-left">
                  <h3 className="main-heading">{Object.keys(element)}</h3>
                  <p
                    className="h6 font-content text-left"
                    dangerouslySetInnerHTML={{
                      __html: element[Object.keys(element)],
                    }}
                  ></p>
                </div>
              </div>
            </div>
          </section>
        );
      } else {
        return (
          <section className="section bg-c-light border-top">
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-center">
                  <h3 className="main-heading">{Object.keys(element)}</h3>
                  <p
                    className="h6 font-content text-left"
                    dangerouslySetInnerHTML={{
                      __html: element[Object.keys(element)],
                    }}
                  ></p>
                </div>
              </div>
            </div>
          </section>
        );
      }
    });
  };

  return (
    <div className="padding">
      <section className="section">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h3 className="main-heading">{content.stander}</h3>
              <div className="underline mx-auto"></div>
              <p className="h3">{content.header}</p>
              <div>
                <p
                  className="h6 font-content text-left"
                  dangerouslySetInnerHTML={{
                    __html: content.headerContent,
                  }}
                ></p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {!excludeList.includes(content.stander) && (
        <section className="section bg-c-light border-top">
          <div className="container">
            <div className="row">
              <div className="col-md-12 mb-5 text-center">
                <h3 className="main-heading">{content.baseQuestion}</h3>
              </div>
              {step()}
            </div>
          </div>
        </section>
      )}

      {questions()}
      <Link to="/">
        <img src={home} alt="home" className="home" />
        <p className="home-page">Home</p>
      </Link>
    </div>
  );
}
export default BASE;
