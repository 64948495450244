import React from "react";

function VMC() {
  return(
<section className="section  border-top">
        <div className="container">
          <div className="row">
            <div className="col-md-12 mb-5 text-center">
              <h3 className="main-heading">Mission and Skills</h3>
              <div className="underline mx-auto"></div>
                </div>
                <div className='col-md-4 text-center'>
                  <h4>OUR MISSION</h4>
                  <p className='size-font'>
                  At TopMetric, we aim to provide the right solution for your business with best conversion policy. Our practices and approach towards our customers are more of growth oriented, which meet the customer’s need of efficiency and cost-effectiveness. 
                  </p>
                </div>
                <div className='col-md-4 text-center'>
                  <h4>WHAT WE DO</h4>
                  <p className='size-font'>
                  TopMetric is one of the few consulting organizations in the world that has domain expertise in the majority of all industry sectors. Our basket is full of various ISO Certifications as well as Compliances.  
                  </p>
                </div>
                <div className='col-md-4 text-center'>
                  <h4>Our Skills </h4>
                  <p className='size-font'>
                  ISO Certification, CE Mark, HACCP, GDPR, HIPAA, CMMI, SOC 1 & SOC 2, SMETA.
                  </p>
                </div>
          </div>
        </div>
      </section>
  )
  
}
export default VMC;