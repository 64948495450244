import React from "react";
import Reviews from "../../slider.json";
function Feedbacks() {
  const reviewMap = () => {
    return Reviews?.reviews.map((element, i) => {
      if (i === 0) {
        return (
          <div className="carousel-item active"  key={i}>
            <div className="row">
              <div className="card mb-3 feedback card-background">
                <div className="row g-0">
                  <div className="col-md-12">
                    <div className="card-body">
                      <h5 className="card-title">{element.name}</h5>
                      <h6 className="card-title">{element.designation}</h6>
                      <p className="card-text size-l">{element.review}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <div className="carousel-item">
            <div className="row">
              <div className="card mb-3 feedback card-background">
                <div className="row g-0">
                  <div className="col-md-12">
                    <div className="card-body">
                      <h5 className="card-title">{element.name}</h5>
                      <h6 className="card-title">{element.designation}</h6>
                      <p className="card-text size-l">{element.review}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }
    });
  };
  return (
    <div>
      <section className="section bg-c-light border-top feedback-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12 mb-5 text-center">
              <h3 className="main-heading">Customer Reviews</h3>
              <div className="underline mx-auto"></div>
            </div>
            <div
              id="carouselExampleIndicators"
              className="carousel slide"
              data-ride="carousel"
            >
              <ol className="carousel-indicators">
                <li
                  data-target="#carouselExampleIndicators"
                  data-slide-to="0"
                  className="active"
                ></li>
                <li
                  data-target="#carouselExampleIndicators"
                  data-slide-to="1"
                ></li>
                <li
                  data-target="#carouselExampleIndicators"
                  data-slide-to="2"
                ></li>
              </ol>
              <div className="carousel-inner">{reviewMap()}</div>
              <a
                className="carousel-control-prev"
                href="#carouselExampleIndicators"
                role="button"
                data-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
              </a>
              <a
                className="carousel-control-next"
                href="#carouselExampleIndicators"
                role="button"
                data-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default Feedbacks;
