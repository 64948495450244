import React from "react";
import { Link } from "react-router-dom";
import Standards from "../../standards.json";
import scroll from "../images/scrollbar.png";
function Services() {
  window.scrollTo(0, 0);
  const ServicesStandards = () => {
    return Standards.standards.map((element, i) => {
      if (i < 9)
        return (
          <div className="col-lg-4 d-flex mb-3 align-items-stretch" key={i}>
            <div className="card shadow">
              <div className="py-4 text-center text-orange">
                <h2>{element.header}</h2>
              </div>
              <div className="card-body">
                <p className="text">{element.content}</p>
                <Link to={element.Route} className="btn btn-link text-orange">
                  read more
                </Link>
              </div>
            </div>
          </div>
        );
    });
  };
  return (
    <section className="section  border-top">
      <div className="container">
        <div className="row">
          <div className="col-md-12 mb-5 text-center">
            <h3 className="main-heading">Our Services</h3>
            <div className="underline mx-auto"></div>
          </div>
          <div className="row">{ServicesStandards()}</div>
        </div>
        <div className="align-center">
          <div className="col-md-12 mt-5 text-center">
            <Link to="/standards">
              <img src={scroll} alt="scroll" className="scroll" />
            </Link>
            
          </div>
          <Link to="/standards" className="text-orange">
          see More
          </Link>
        </div>
      </div>
    </section>
  );
}
export default Services;
