import React from 'react';
import { Link } from "react-router-dom";
import home from "../images/home.png";
function About() {
  window.scrollTo(0, 0);
const location = window.location.pathname;
  return(
    <div className="padding">
        <section className="py-4 bg-info">
        <div className="container">
          <div className="row">
            <div className="col-md-4 my-auto">
              <h4>About Us</h4>
            </div>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h3 className="main-heading">TopMetric</h3>
              <div className="underline mx-auto"></div>
              <p className="p-tag-home">
                We here at TopMetric believe in delivering quality. Perfection
                is guaranteed. With the help of our highly expert and qualified
                professionals, it is our duty to guide you to choose the right
                ISO certificate/compliance for your beloved business. We take
                care of your requirement with utmost professionalism.<br/> We eye on
                delivering true business benefits with our consultation process.
                Our approach is straight forward and easy to understand.<br/>
                Together, we are a handful professionals, taking care of our
                global customer base and an illustrious service portfolio that
                covers all the International Quality Certifications.
              </p>
            </div>
          </div>
        </div>
        {location === "/contact" ? (
        <Link to="/">
          <img src={home} alt="home" className="home" />
          <p className="home-page">Home</p>
        </Link>
      ) : (
        ""
      )}
      </section>

    </div>
  )
  
}
export default About;