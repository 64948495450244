import React from "react";
import "./App.css";
import Footer from "./components/inc/Footer";
import Navbar from "./components/inc/Navbar";
import Home from "./components/pages/home";
import About from "./components/pages/about";
import Contact from "./components/pages/contact";
import Base from "./components/inc/baseread";
import Standards from "./components/inc/allStandards";
import Consulting from "./components/inc/consulting";
import Data from "./data.json";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
function App() {
  const baseRoutes = () => {
    return Data.mainContent.map((element, i) => {
      return (
        <Route
          path={element.route}
          exact
          element={<Base Data={element} />}
          key={i}
        />
      );
    });
  };

  return (
    <Router>
      <div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover toastStyle={{ backgroundColor: "orange" }}
        />
        <Navbar />
        <Routes>
          {baseRoutes()}
          <Route path="/" exact element={<Home />} />
          <Route path="/consulting" exact element={<Consulting />} />
          <Route path="/standards" exact element={<Standards />} />
          <Route path="/about" exact element={<About />} />
          <Route path="/contact" exact element={<Contact />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
