import React from "react";
import { Link } from "react-router-dom";
import home from "../images/home.png";
function Consulting() {
  window.scrollTo(0, 0);
  const location = window.location.pathname;
  return (
    <div className="padding">
    <section className="section bg-c-light border-top">
      <div className="container">
        <div className="row">
          <div className="col-md-12 mb-5 text-center">
            <h3 className="main-heading">Our consulting process</h3>
            <div className="underline mx-auto"></div>
          </div>
          </div>
          <div className="row">
          <div className="col-lg-4 d-flex mb-3 align-items-stretch">
            <div className="card shadow">
              <div className="py-4 text-center text-orange">
                <h4>GAP Analysis</h4>
              </div>
              <div className="card-body">
                <p className="size-f">
                  Companies employ a gap analysis to compare their existing
                  performance to their intended, expected performance. This
                  study is performed to see if a firm is achieving expectations
                  and making good use of its resources.
                  <br />
                  <br />
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 d-flex mb-3 align-items-stretch">
            <div className="card shadow">
              <div className="py-4 text-center text-orange">
                <h4>Awareness Training</h4>
              </div>
              <div className="card-body">
                <p className="size-f">
                  Awareness training is a part, designed to educate consumers
                  and workers understand their responsibility in preventing data
                  security breaches. Employees benefit from awareness training
                  because it helps them comprehend dangers and recognize
                  possible threats.
                  <br />
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 d-flex mb-3 align-items-stretch">
            <div className="card shadow">
              <div className="py-4 text-center text-orange">
                <h4>Documentation Support </h4>
              </div>
              <div className="card-body">
                <p className="size-f">
                  ISO makes documents with required standards, specifications,
                  guidelines or characteristics. These can consistently be used
                  by companies and ensure that materials, products, processes
                  and services are suitable for their purpose.
                  <br />
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 d-flex mb-3 align-items-stretch">
            <div className="card shadow">
              <div className="py-4 text-center text-orange">
                <h4>Internal Audit Training </h4>
              </div>
              <div className="card-body">
                <p className="size-f f-style">
                  Internal auditor Training will be provided to the Identified
                  personnel. This training will equip such personnel to analyze
                  the need for Internal auditor, Plan and schedule Internal
                  auditor, prepare audit checklists, and conduct an Internal
                  auditor and to document and report their observations to the
                  top management.
                  <br />
                  <br />
                  <br />
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 d-flex mb-3 align-items-stretch">
            <div className="card shadow">
              <div className="py-4 text-center text-orange">
                <h4>Internal Audit </h4>
              </div>
              <div className="card-body">
                <p className="size-f">
                  An impartial service that evaluates an organization's internal
                  controls, corporate practices, procedures, and methodologies
                  is known as internal audit. Internal audits assist in ensuring
                  compliance with numerous regulations that apply to a company.
                  <br />
                  <br />
                  <br />
                  <br />
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 d-flex mb-3 align-items-stretch">
            <div className="card shadow">
              <div className="py-4 text-center text-orange">
                <h4>External Audit </h4>
              </div>
              <div className="card-body">
                <p className="size-f">
                  An external audit is the final step before obtaining your ISO
                  certification. The auditor will ensure your documentation
                  meets all the requirements of the standard. He or she will
                  also make sure that all procedures and processes are correctly
                  followed. Depending on the findings, the auditor will either
                  recommend you be granted your certification or require
                  corrective actions before you can be accredited.
                  <br />
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {location === "/consulting" ? (
        <Link to="/">
          <img src={home} alt="home" className="home" />
          <p className="home-page">Home</p>
        </Link>
      ) : (
        ""
      )}
    </section>
    </div>
  );
}
export default Consulting;
